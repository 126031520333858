code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
hr {
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

html, body, #root {
  height: 100%;
  background-color: #ffffff
}

.gm-style-iw-c {
  padding-right: 12px !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  box-shadow: unset !important;
  max-width: unset !important;
}

.gm-style-iw-d {
  max-height: unset !important;
  max-width: unset !important;
  overflow: unset !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

.vertical-timeline-element-date {
  display: none !important
}

/* Hide input type number arrows in Chrome, Safari, Edge, Opera, Firefox */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

/* CMS Article Content Styling */
#article-content > *{
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: justify;
}

#article-content p, #article-content ul, #article-content ol {
  color: #707070;
  font-size: 14px;
  line-height: 1.6;
}

#article-content h2 {
  font-size: 18px !important;
}

#article-content h3 {
  font-size: 16px !important;
}

#article-content h4 {
  font-size: 14px !important;
}

#article-content a {
  color: #1a0dab;
  text-decoration: none;
  font-size: 14px;
}

#article-content a:hover {
  text-decoration: underline;
}


#article-content figure {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 14px;
}

#article-content img {
  max-width: 100%;
}

#article-content table {
  text-align: center;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  border: solid 1px #707070;
  border-collapse: collapse;
}

#article-content td {
 border: solid 1px #707070;
 color: #707070;
 padding: 8px;
}

/* Circular Menu Styling */
.circular-menu {
  position: fixed;
}

.circular-menu .floating-btn {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #1D3D90;
  box-shadow: 0 2px 5px 0 hsla(0, 0%, 0%, .26);  
  color: hsl(0, 0%, 100%);
  line-height: 3.9;
  cursor: pointer;
  outline: 0;
  align-items: center;
  justify-content: inherit;
  padding: 0px !important;
}

.circular-menu.active .floating-btn {
  box-shadow: inset 0 0 3px hsla(0, 0%, 0%, .3);
}

.circular-menu .floating-btn:active {
  box-shadow: 0 4px 8px 0 hsla(0, 0%, 0%, .4);
}

.circular-menu:after {
  display: block;
  content: ' ';
  width: 50px;
  height: 50px;
  background-color:#2366C9;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -2;
  transition: all .3s ease;
}

.circular-menu.active:after {
  transform: scale(7);
  transition-timing-function: cubic-bezier(.68, 1.55, .265, 1);
}

.circular-menu .items-wrapper {
  padding: 0;
  margin: 0;
  visibility: hidden;
}

.circular-menu.active .items-wrapper {
  padding: 0;
  margin: 0;
  visibility: visible;
}

.circular-menu .menu-item {
  position: absolute;
  top: .2em;
  right: .2em;
  z-index: -1;
  display: block;
  text-decoration: none;
  font-size: 1em;
  text-align: center;
  line-height: 3;
  transition: transform .3s ease, background .2s ease;
  opacity: 0;
}

.circular-menu.active .menu-item {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  opacity: 1;
}

.circular-menu .floating-btn #help-button-icon {
  transition: transform .3s;  
  visibility: visible;
}

.circular-menu.active .floating-btn #help-button-icon {
  transform: scale(0);
  visibility: hidden;
}

.circular-menu .floating-btn #close-icon {
  transition: transform .3s;  
  transform: scale(0);
  visibility: hidden;
}

.circular-menu.active .floating-btn #close-icon {
  transform: scale(1);
  visibility: visible;
}

.circular-menu.active .menu-item:nth-child(1) {
  transform: translate3d(-15px,-100px,0);
}

.circular-menu.active .menu-item:nth-child(2) {
  transform: translate3d(-70px,-20px,0);
}

.circular-menu.active .menu-item:nth-child(3) {
  transform: translate3d(-40px,70px,0);
}

/* Override React Calendar Styling */
.react-calendar__month-view__days__day.react-calendar__tile--active {
  background-color: #104eb2 !important;
}

.react-calendar__month-view__days__day.react-calendar__tile--active:hover {
  background-color: #003897 !important;
}

.react-calendar__month-view__days__day:not(.react-calendar__tile--active, :disabled) {
  background-color: white !important;
}

.react-calendar__month-view__days__day:hover:not(.react-calendar__tile--active, :disabled) {
  background-color: #e6e6e6 !important;
}

.react-calendar__month-view__days__day--weekend:disabled {
  color: rgba(16, 16, 16, 0.3) !important
}

.react-calendar__month-view__days__day--neighboringMonth:disabled {
  color: #f0f0f0 !important
}

.react-calendar__month-view__days__day--weekend,
.react-calendar__month-view__days__day--neighboringMonth {
 color: buttontext !important
}

.react-calendar__month-view__days__day--weekend.react-calendar__tile--active {
  color: white !important;
}

.react-calendar__year-view__months__month.react-calendar__tile--now {
  background-color: unset;
}

.react-calendar__year-view__months__month.react-calendar__tile--hasActive {
  background-color: #104eb2;
  color: white;
}

.react-calendar__year-view__months__month.react-calendar__tile--hasActive:hover {
  background-color: #003897 !important;
}

.react-calendar__year-view__months__month.react-calendar__tile--now:hover:not(.react-calendar__tile--hasActive) {
  background-color: #e6e6e6 !important;
}

/* Recaptcha v3 */
.grecaptcha-badge {
  visibility: hidden;
}