.spinner {
  animation: rotator 1.4s linear infinite;
  -webkit-animation: rotator 1.4s linear infinite;
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
 }
  100% {
    transform: rotate(270deg);
 }
}
@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg);
 }
  100% {
    transform: rotate(270deg);
 }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  -webkit-transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
  -webkit-animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}
@keyframes colors {
  0% {
    stroke: #e1fff9;
 }
  10% {
    stroke: #bbfff2;
 }
  20% {
    stroke: #76ffe5;
 }
  30% {
    stroke: #57f7d8;
 }
  40% {
    stroke: #3bedcb;
 }
  50% {
    stroke: #2ee5c6;
 }
  60% {
    stroke: #26dbc0;
 }
  70% {
    stroke: #1bceb8;
 }
  80% {
    stroke: #0dbfba;
 }
  90% {
    stroke: #0fb8bc;
 }
  100% {
    stroke: #11aeba;
 }
}
@-webkit-keyframes colors {
  0% {
    stroke: #e1fff9;
 }
  10% {
    stroke: #bbfff2;
 }
  20% {
    stroke: #76ffe5;
 }
  30% {
    stroke: #57f7d8;
 }
  40% {
    stroke: #3bedcb;
 }
  50% {
    stroke: #2ee5c6;
 }
  60% {
    stroke: #26dbc0;
 }
  70% {
    stroke: #1bceb8;
 }
  80% {
    stroke: #0dbfba;
 }
  90% {
    stroke: #0fb8bc;
 }
  100% {
    stroke: #11aeba;
 }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
 }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
 }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
 }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 187;
 }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
 }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
 }
}
